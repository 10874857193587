import AOS from 'aos';
document.addEventListener('DOMContentLoaded', function() {
    AOS.init({
        offset: 200,
        duration: 800,
        once: true,
        startEvent: 'turbolinks:load'
    });
});

document.addEventListener("turbolinks:load", function (e) {
    $.HSCore.components.HSHeader.init($('#header'));
    $.HSCore.components.HSGoTo.init('.js-go-to');

    // initialization of HSMegaMenu component
    $('.js-mega-menu').HSMegaMenu({
        event: 'hover',
        pageContainer: $('.container'),
        breakpoint: 991,
        hideTimeOut: 0
    });

    $('#homeMegaMenu').on('click', function() {
        if (window.innerWidth > 991) {
            Turbolinks.visit('/biz')
        }
    });

    $('a[href^="#"]').on('click', function(){
        let speed = 500;
        let href= $(this).attr("href");
        let target = $(href === "#" || href === "" ? 'html' : href);
        let position = target.offset().top - 40;
        $("html, body").animate({scrollTop:position}, speed, "swing");
        return false;
    });

    AOS.init({
        offset: 200,
        duration: 800,
        once: true,
        disable: 'mobile',
        startEvent: 'turbolinks:load'
    });

    const $mg_popup = $('.mg_popup');
    if ($mg_popup.length > 0) {
        $mg_popup.magnificPopup({
            type: 'image'
        });
    }

    $('.function_card').on('click', function() {
        const kind = $(this).data('kind');
        if (kind === 'relational' || kind === 'program') {
            Turbolinks.visit('/features/' + $(this).data('kind'))
        } else {
            Turbolinks.visit('/functions/' + $(this).data('kind'))
        }
    })
});
